import React from 'react'
import { createRenderTarget } from '../../../render-target'
import { breakpoints } from '../../../__styling/settings/breakpoints'
import { calcRem } from '../../../__styling/style-functions/calc-rem'
import { ImageLogoProps, StyledAmpLogo } from '../TheWestHeader.styled'

export interface LogoProps extends ImageLogoProps {
    src: string
    alt: string
}

export function createHeaderLogo(
    Component: React.ComponentType<LogoProps>,
    props: LogoProps,
): React.FC<{}> {
    const HeaderLogo = createRenderTarget<{}>('HeaderLogo', {
        amp: () => {
            const { src, alt, ampInitialSize, heights } = props
            const { width, height } = ampInitialSize

            const getAmpWidthFromHeight = (value: number) =>
                value / (height / width)

            return (
                <StyledAmpLogo
                    alt={alt}
                    src={src}
                    width={width}
                    height={height}
                    sizes={
                        heights
                            ? `(max-width: ${calcRem(
                                  breakpoints.xxs,
                              )}) ${calcRem(
                                  getAmpWidthFromHeight(heights.mobile),
                              )}, (min-width: ${calcRem(
                                  breakpoints.xxs + 1,
                              )}) and (max-width: ${calcRem(
                                  breakpoints.sm,
                              )}) ${calcRem(
                                  getAmpWidthFromHeight(heights.tablet),
                              )}, ${calcRem(
                                  getAmpWidthFromHeight(heights.desktop),
                              )}`
                            : undefined
                    }
                />
            )
        },
        web: () => <Component {...props} />,
        rss: () => <img {...props} alt={props.alt} />,
        preview: () => <Component {...props} />,
        app: () => <Component {...props} />,
    })

    return HeaderLogo
}
